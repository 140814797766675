import React, { Component } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Pagination, Footer, Overlay, PTPHeader } from '../../components/common'
import { Filters, ResultList } from '../../components/search'
import { SearchHelper } from '../../helpers'
import start from '../../assets/images/start.svg'
import arrowUp from '../../assets/images/arrow-up.svg'

import '../../assets/styles/app.scss'
import { SECTIONS } from '../../utils/url'
import { addHeadScript } from '../../utils/import-embed-script'
import { defaultFacebookPixel } from '../../components/common/FacekookPixel'

const HITS_PER_PAGE = 20 // How many results we show per page
const PAGINATION_SIZE_MOBILE = 5 // How many pages we show in the footer
const PAGINATION_SIZE_DESKTOP = 10 // How many pages we show in the footer

class SearchPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            page: 1,
            paginationSize: PAGINATION_SIZE_MOBILE,
            showScroll: false,
        }
    }

    componentDidMount() {
        addHeadScript(defaultFacebookPixel)
        const queryParams = SearchHelper.queryParamsToObject(this.props.location.search)
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        this.setState({
            isMobile,
            ...SearchHelper.deserializeSearchState(queryParams),
            paginationSize: isMobile ? PAGINATION_SIZE_MOBILE : PAGINATION_SIZE_DESKTOP,
        })
        window.addEventListener('scroll', this.handleScroll)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        if (window.scrollY > 300) {
            this.setState({ showScroll: true })
        } else {
            this.setState({ showScroll: false })
        }
    }

    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    render() {
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemapPtp.edges', [])
        sitemap = sitemap.map((link) => link && link.node)
        return (
            <div className={`app-root ptp-root`}>
                <div className=" advanced-search">
                    <Helmet title="Power To Protect" />
                    <PTPHeader activeSection={SECTIONS.VISITOR.key} inferSearchSection={false} />
                    <main className={`wrapper `}>
                        <div className={'content'}>
                            <InstantSearch appId={process.env.GATSBY_ALGOLIA_APP_ID} apiKey={process.env.GATSBY_ALGOLIA_SEARCH_KEY} indexName="power-to-protect" searchState={this.state} onSearchStateChange={(searchState) => (window.location.href = SearchHelper.serializeSearchState(searchState))}>
                                <Configure facetingAfterDistinct={true} hitsPerPage={HITS_PER_PAGE} analytics={true} />
                                <Filters isPowerToProtect />
                                <h4>Results</h4>
                                <ResultList />
                                <Pagination size={this.state.paginationSize} />
                            </InstantSearch>
                        </div>
                    </main>
                    <Footer activeSection={SECTIONS.VISITOR.key} sitemap={sitemap} isPowerToProtect={true} />
                    <Overlay />
                </div>

                <div className="floating-button">
                    <Link to="/" className="go-to-starship">
                        <img src={start} alt="starship" />
                        <span>Back to Starship </span>
                    </Link>
                    {this.state.showScroll && (
                        <button className="scroll-to-top" onClick={this.scrollToTop}>
                            <img src={arrowUp} alt="Go to top" />
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

export const pageQuery = graphql`
    query getSiteMapPtpSearch {
        allContentfulWidgetSitemapPtp(filter: { title: { ne: "Don't delete - sitemap" } }) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageDirectoryOfServicesPtp {
                            id
                            title
                        }
                        ... on ContentfulPageGenericPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroupPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageWidgetsPtp {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`

export default SearchPage
